<template>
  <div class="font-source">
    <section class="block lg:flex justify-between">
      <div class="text-base lg:text-xl">
        <h4 class="font-semibold text-ansBlack text-base lg:text-xl">
          Dashboard
        </h4>
        <small class="text-ghostWhite mt-0 lg:mt-1 text-base lg:text-xl">
          Welcome {{ $store.state.general.user.companyName }}
        </small>
      </div>

      <div
        class="flex justify-between gap-5 mt-5 lg:mt-0 w-full lg:w-auto font-semibold text-xs lg:text-base"
      >
        <button
          @click="showCreateItem = true"
          class="bg-ansLemon text-ansGreen px-2.5 lg:px-4 py-3 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <img src="@/assets/images/icons/plus.svg" alt="icons" />
          <span>Generate New Invoice</span>
        </button>

        <button
          @click="showCreate = true"
          class="bg-ansGreen text-white px-2.5 lg:px-4 py-3 rounded flex gap-3 items-center w-1/2 lg:w-auto"
        >
          <img
            src="@/assets/images/icons/agents.svg"
            alt="icons"
            class="whither"
          />
          <span>Create New Sub Agent</span>
        </button>
      </div>
    </section>

    <!-- Boxes -->
    <section class="mt-10" id="dboxes">
      <!-- Big boxes -->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 h-auto mt-5">
        <div class="bg-ansGreen big-boxes">
          <h6>
            Total Value of
            <span class="ml-1 text-xs font-bold">TRANSACTIONS</span>
          </h6>

          <h3 class="mt-4">
            <!-- <span class="bg-white text-ansGreen rounded py-0.5 px-1.5 text-lg"
              >₦</span
            > -->
            <span class="font-black">
              {{ totalTransactionAmount | toCurrency }}
            </span>
          </h3>
        </div>
        <div class="border border-gray-200 big-boxes">
          <h6 class="text-cadet">
            Total Value of Generated
            <span class="ml-1 text-xs font-bold">INVOICES</span>
          </h6>

          <h3 class="mt-4 text-ansBlack">
            <!-- <span class="text-white bg-ansGreen rounded py-0.5 px-1.5 text-lg"
              >₦</span> -->

            <span class="font-black">
              {{ totalGeneratedTaxIncome | toCurrency }}
            </span>
          </h3>
        </div>
        <div class="bg-ansGreen big-boxes">
          <h6 class="">
            Total Number of Generated
            <span class="ml-1 text-xs font-bold">INVOICES</span>
          </h6>

          <h3 class="mt-4 font-black">{{ totalGeneratedInvoice }}</h3>
        </div>
      </div>

      <!-- Small boxes -->
      <div
        class="flex gap-6 h-auto mt-8 py-4 px-5 rounded border overflow-x-scroll border-gray-100"
      >
        <div class="small-boxes bg-[#EAFFF4]">
          <h6 class="font-bold text-cadet">
            Total Number of Paid <br />
            <span class="ml-1 text-xs font-normal">INVOICES</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">{{ totalPaidInvoice }}</h3>
        </div>
        <div class="small-boxes bg-[#FFE8EA]">
          <h6 class="font-bold text-cadet">
            Total Number of Pending <br />
            <span class="ml-1 text-xs font-normal">INVOICES</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">
            {{ totalPendingInvoice }}
          </h3>
        </div>
        <div class="small-boxes bg-[#F2F9FF]">
          <h6 class="font-bold text-cadet">
            Total Number of <br />
            <span class="ml-1 text-xs font-normal">TRANSACTIONS</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">
            {{ totalNumberTransactions }}
          </h3>
        </div>
        <div class="small-boxes bg-[#F5F5F5]">
          <h6 class="font-bold text-cadet">
            Number of <br />
            <span class="ml-1 text-xs font-normal">SUB-AGENTS</span>
          </h6>

          <h3 class="mt-4 font-black text-ansBlack">{{ totalSubAgents }}</h3>
        </div>
      </div>
    </section>

    <!-- Tables -->
    <section class="mt-6">
      <h3 class="text-cadet font-bold text-base lg:text-2xl">
        Recent Activity
      </h3>

      <div class="block lg:flex justify-between font-source mt-3 pb-10 gap-10">
        <div class="table-box">
          <div class="flex justify-between px-2 mb-4">
            <h4>Invoices</h4>

            <router-link :to="{ name: 'Agent Invoices' }">
              View all
            </router-link>
          </div>

          <my-table
            :table-data="recentInvoices"
            :fields="fInv"
            :show-page="false"
            :show-search="false"
          >
          </my-table>
        </div>

        <div class="table-box">
          <div class="flex justify-between px-2 mb-4">
            <h4>Transactions</h4>

            <router-link :to="{ name: 'Agent Transactions' }">
              View all
            </router-link>
          </div>

          <my-table
            :table-data="recentTransactions"
            :fields="fTrans"
            :show-page="false"
            :show-search="false"
          >
          </my-table>
        </div>
      </div>
    </section>

    <Modal v-if="showCreateItem" v-model="showCreateItem" size="lg">
      <CreateInvoice @done="onAddItem" />
    </Modal>
    <Modal v-if="showCreate" v-model="showCreate">
      <CreateSubAgent @add-agent="onAddAgent" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/app/Modal";
import CreateInvoice from "@/components/agent/CreateInvoice";
import CreateSubAgent from "@/components/agent/CreateSubAgent";
export default {
  name: "AgentDashboard",

  components: {
    Modal,
    CreateInvoice,
    CreateSubAgent,
  },

  async created() {
    await this.getData();
  },

  data() {
    return {
      showCreate: false,
      showCreateItem: false,
      fInv: [
        {
          name: "invoiceNumber",
          title: "Invoice Number",
          titleClass:
            "w-1/3 text-left pl-4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-left pl-4 text-xs text-romanSilver",
        },
        {
          name: "invoiceStatus",
          title: "Status",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass:
            "w-1/3 py-2 text-center text-xs text-romanSilver uppercase",
        },
        {
          name: "dateOfIssuance",
          title: "Created",
          titleClass:
            "w-1/3 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-center text-xs text-romanSilver",
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
      ],
      fTrans: [
        {
          name: "referenceCode",
          title: "Tracking code",
          titleClass:
            "w-1/4 text-left pl-4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/4 py-2 text-left pl-4 text-xs text-romanSilver",
        },
        {
          name: "amount",
          title: "Amount (₦)",
          titleClass:
            "w-1/4 bg-gray-100 py-2.5 text-left text-cadet font-semibold text-sm",
          dataClass: "w-1/3 py-2 text-left text-xs text-romanSilver",
          formatter: (value) => this.moneyFormatter(value),
        },
        {
          name: "transactionStatus",
          title: "Status",
          titleClass:
            "w-1/4 bg-gray-100 py-2.5 text-left text-cadet font-semibold text-sm",
          dataClass: "w-1/4 py-2 text-left text-xs text-romanSilver uppercase",
        },
        {
          name: "date",
          title: "Created",
          titleClass:
            "w-1/4 bg-gray-100 py-2.5 text-cadet font-semibold text-sm",
          dataClass: "w-1/4 py-2 text-center text-xs text-romanSilver",
          formatter: (val) => this.moment(val).format("DD-MM-YYYY"),
        },
      ],
      recentInvoices: [],
      recentTransactions: [],
      totalPaidInvoice: 0,
      totalPendingInvoice: 0,
      totalSubAgents: 0,
      totalGeneratedTaxIncome: 0,
      totalGeneratedInvoice: 0,
      totalNumberTransactions: 0,
      totalTransactionAmount: 0,
    };
  },

  methods: {
    onAction(action, data, index) {
      console.log("slot action: " + action, data, index);
    },
    onAddAgent() {
      this.showCreate = false;
    },
    onAddItem() {
      this.showCreateItem = false;
    },
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get("/dashboard");
        this.getting = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.recentInvoices = data.data.recentInvoices;
        this.recentTransactions = data.data.recentTransactions;
        this.totalPaidInvoice = data.data.totalPaidInvoice;
        this.totalPendingInvoice = data.data.totalPendingInvoice;
        this.totalSubAgents = data.data.totalSubAgents;
        this.totalGeneratedTaxIncome = data.data.totalGeneratedTaxIncome;
        this.totalGeneratedInvoice = data.data.totalGeneratedInvoice;
        this.totalTransactionAmount = data.data.totalTransactionAmount;
        this.totalNumberTransactions = data.data.totalNumberTransactions;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped>
.big-boxes {
  @apply rounded p-5 text-white;
}
.small-boxes {
  @apply p-2.5 rounded w-1/4;
  min-width: 150px;
}
.table-box {
  @apply rounded-md py-4 px-2 w-full lg:w-1/2 mt-5 lg:mt-0;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 11px rgba(200, 212, 219, 0.2);
}
.table-box h4 {
  @apply text-romanSilver font-bold;
}
.table-box > div > a {
  @apply text-ansGreen font-semibold;
}
</style>
